import React, { useRef, useState, useEffect } from "react";
import "./Destination.css";
import { Form, Row, Col, ListGroup, Offcanvas, Button, Container, Modal, Collapse } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import TableDateTimePicker from "./TableDateTimePicker";
import { useAppContext } from "../libs/contextLib";
import { FaHandPointRight } from "react-icons/fa";
import axiosInstance from "../axiosApi";
import axios from 'axios';
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";
import config from "../config";
import * as dateFns from "date-fns";

export default function ChooseRouteSection() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [carOptions, setCarOptions] = useState([]);
    const [personsOptions, setPersonsOptions] = useState([]);
    const [routeSectionOptions, setRouteSectionOptions] = useState([]);
    const [persons, setPersons] = useState(0);

    const { setRouteAppContext } = useAppContext();
    const { routeAppContext } = useAppContext();

    const { ticketGubunAppContext } = useAppContext();
    const { setTicketGubunAppContext } = useAppContext();

    const { strRoundDateAppContext } = useAppContext();
    const { setStrRoundDateAppContext } = useAppContext();

    const { endRoundDateAppContext } = useAppContext();
    const { setEndRoundDateAppContext } = useAppContext();

    const { strStationAppContext } = useAppContext();
    const { setStrStationAppContext } = useAppContext();

    const { endStationAppContext } = useAppContext();
    const { setEndStationAppContext } = useAppContext();

    const { endSingleDateAppContext } = useAppContext();
    const { setEndSingleDateAppContext } = useAppContext();

    const { chargeAppContext } = useAppContext();
    const { setChargeAppContext } = useAppContext();

    const { leadtimeAppContext, setLeadtimeAppContext } = useAppContext();
    const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
    const { routeMap, setRouteMap } = useAppContext();

    const { setCarAppContext } = useAppContext();
    const { carAppContext } = useAppContext();

    const { setCarRoundAppContext } = useAppContext();
    const { carRoundAppContext } = useAppContext();

    const { setStrSingleDateAppContext } = useAppContext();
    const { strSingleDateAppContext } = useAppContext();

    const [station, setStation] = useState([]);
    const [strStationOptions, setStrStationOptions] = useState([]);
    const [endStationOptions, setEndStationOptions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [bookPersons, setBookPersons] = useState([]);

    const { t } = useTranslation();

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    function showModal() {
        setModalShow(true);
    }

    function hideModal() {
        setPersonsOptions([]);
        setPersons(0);
        setModalShow(false);
    }

    var singlelt = 0, roundlt = 0, singlerd = 0, roundrd = 0;

    useEffect(() => {
        async function onLoad() {
            await axiosInstance.get('/robotaxi/robocar_run/', {
                params: { route_id: routeAppContext.id, section_id: routeSectionAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                const cars = response.data;
                setCarAppContext(response.data[0]);
                setCarOptions(cars.map(data => ({ name: data.carname, id: data.id, car: data })));
            }).catch(function (error) {
                setIsLoading(false);
            });

            await axiosInstance.get('/robotaxi/routesection/', {
                params: { route_id: routeAppContext.id, is_publish: '1' }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setRouteSectionOptions(response.data.map(data => ({ name: data.sectionname, id: data.id, section: data })));
            }).catch(function (error) {
                setIsLoading(false);
            });

            axiosInstance.get('/robotaxi/routemap/', {
                params: { id: routeAppContext.id, section: routeSectionAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                //console.log(response.data);
                var s_id = "";
                var e_id = "";
                setStation(response.data);

                if (routeAppContext.routegubun == "B") {
                    if (endStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence < endStationAppContext.sequence) {
                                setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                    if (strStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence > strStationAppContext.sequence) {
                                setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                } else {
                    if (endStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence !== endStationAppContext.sequence) {
                                setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                    if (strStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence !== strStationAppContext.sequence) {
                                setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                }
            }).catch(function (error) {
                setIsLoading(false);
            });

        }

        onLoad();
    }, []);

    async function getChargeAndTimeFinal() {
        const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
            params: {
                id: routeAppContext.id,
                section: routeSectionAppContext.id,
                s_book: strStationAppContext.station.id,
                e_book: endStationAppContext.station.id
            }
        });
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        let strtime, endtime, rstrtime, rendtime;
        strtime = new Date(strSingleDateAppContext);
        endtime = moment(strtime).add(Number(response.data.sum_leadtime), 'minutes')
        setEndSingleDateAppContext(endtime);

        rstrtime = new Date(strRoundDateAppContext);
        rendtime = moment(rstrtime).add(Number(response.data.sum_leadtime), 'minutes')
        setEndRoundDateAppContext(rendtime);

        if (routeAppContext.is_pay) {
            if (routeAppContext.routegubun == 'B') {
                setChargeAppContext(String(routeAppContext.charge * persons));
            } else {
                setChargeAppContext(String(routeAppContext.charge));
            }
        } else {
            setChargeAppContext(String(0));
        }
        setLeadtimeAppContext(response.data.sum_leadtime);
        singlelt = Number(response.data.sum_leadtime);
        singlerd = Number(response.data.sum_rundistance);
    }

    async function getChargeAndTime(s_id, e_id, route_id, section_id) {
        try {
            const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
                params: {
                    id: route_id,
                    section: section_id,
                    s_book: s_id,
                    e_book: e_id
                }
            });
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            setLeadtimeAppContext(response.data.sum_leadtime);

        } catch (error) {
            console.log(error);
        }
    }

    //로그인전에 기본값 설정
    const initialPreSetting = async (route_id, routesection_id) => {
        var s_id = "";
        var e_id = "";
        var section_id = "";

        if (routesection_id) {
            axiosInstance.get('/robotaxi/routemap_list_allowany/', {
                params: { id: route_id, section: routesection_id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setRouteMap(response.data);
                setStrSingleDateAppContext('');
                setStrStationAppContext(response.data[0]);
                s_id = response.data[0].station.id;
                setEndStationAppContext(response.data[response.data.length - 1]);
                e_id = response.data[response.data.length - 1].station.id;
                getChargeAndTime(s_id, e_id, route_id, routesection_id);
                axiosInstance.get('/robotaxi/robocar_run/', {
                    params: { route_id: route_id, section_id: routesection_id }
                }).then(function (response) {
                    if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                    setCarAppContext(response.data[0]);
                }).catch(function (error) {
                    console.log(error);
                });
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`, {
                params: { route_id: route_id, is_publish: '1' }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                //console.log(response.data);
                setRouteSectionAppContext(response.data[0]);
                section_id = response.data[0].id;

                axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`, {
                    params: { id: route_id, section: section_id }
                }).then(function (response) {
                    if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                    setRouteMap(response.data);
                    setStrSingleDateAppContext('');
                    setStrStationAppContext(response.data[0]);
                    s_id = response.data[0].station.id;
                    setEndStationAppContext(response.data[response.data.length - 1]);
                    e_id = response.data[response.data.length - 1].station.id;
                    getChargeAndTime(s_id, e_id, route_id, section_id);

                    axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`, {
                        params: { route_id: route_id, section_id: section_id }
                    }).then(function (response) {
                        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                        setCarAppContext(response.data[0]);
                    }).catch(function (error) {
                        console.log(error);
                    });
                }).catch(function (error) {
                    console.log(error);
                });
                //delay(3000);
            }).catch(function (error) {
                console.log(error);
            });
        }
    };

    //로그인후에 기본값 설정
    const initialPostSetting = async (route_id, routesection_id) => {
        var s_id = "";
        var e_id = "";
        var section_id = "";

        //가장최근 예약건을 가져와서 디폴트값 셋팅
        axiosInstance.get('/robotaxi/bookingcar/last_booking/', {
            params: { route_id: route_id, routesection_id: routesection_id }
        }).then(function (response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            if (response.data.book_date !== "NONE") {
                section_id = response.data.routesection.id;
                s_id = response.data.s_station.id;
                e_id = response.data.e_station.id;
                setRouteSectionAppContext(response.data.routesection);
                setCarAppContext(response.data.car);
                getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
                axiosInstance.get('/robotaxi/routemap_list_allowany/', {
                    params: { id: route_id, section: response.data.routesection.id }
                }).then(function (response) {
                    setRouteMap(response.data);
                    setStrSingleDateAppContext('');
                    response.data.map((data) => {
                        if (data.station.id == s_id) {
                            setStrStationAppContext(data);
                        }
                        if (data.station.id == e_id) {
                            setEndStationAppContext(data);
                        }
                    })
                }).catch(function (error) {
                    console.log(error);
                });
            }
            else {
                initialPreSetting(route_id, routesection_id);
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    async function onChangeRouteSection(event) {
        var s_id = "";
        var e_id = "";
        const result = routeSectionOptions.find(option => Number(option.id) === Number(event.target.value));

        if (result) {
            setRouteSectionAppContext(result.section);

            //가장최근 예약건을 가져와서 디폴트값 셋팅
            await initialPostSetting(routeAppContext.id, result.section.id);
        }

        //delay(3000);
        //if (history.location.pathname == "/bookingstation") {
        //    history.push("/bookingstationstart", { replace: true });
        //} else {
        //    history.push("/bookingstation", { replace: true });
        //}
    }

    return (
        <>
            <div className="bg-white">
                <Form className="form2">
                    <Row className="mx-0 my-0">
                        <Col sm={12} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="my-0" xs={10} md={10}>
                                    <DropdownSelector
                                        labelName=""
                                        options={routeSectionOptions}
                                        defaultValue={routeSectionAppContext === '' ? '' : routeSectionAppContext.id}
                                        onChange={onChangeRouteSection}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}