import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';

function RouteSection() {
  const { t } = useTranslation();
  const [sections, setSections] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const { routeAppContext } = useAppContext();

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      axiosInstance.get('/robotaxi/routesection/', {
        params: { route_id: routeAppContext.id }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        const sections = response.data;
        setSections(sections);
      }).catch(function (error) {
        setIsLoading(false);
      });

      setIsLoading(false);
    }

    onLoad();
  }, []);

  function renderList(sections) {
    return (
      <>
        <LinkContainer to="/routesection/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a New RouteSection</span>
          </ListGroup.Item>
        </LinkContainer>
        {sections.map(({ id, sectionname, mapurl, sectioncolor, crt_date }) => (
          <LinkContainer key={id} to={`/routesection/${id}`}>
            <ListGroup.Item action>
              <span className="font-weight-bold">
                {sectionname}
              </span>
              <br />
              <span className="font-weight-bold">
                {mapurl}
              </span>
              <br />
              <span className="font-weight-bold">
                {sectioncolor}
              </span>
              <br />
              <br />
              <span className="text-muted">
                Created: {new Date(crt_date).toLocaleString()}
              </span>
            </ListGroup.Item>
          </LinkContainer>
        ))}
      </>
    );
  }

  return (
    <Stack gap={3}>
      <div className="bg-light border titlesubject">
      {t('LINEMGT')}
      </div>
      <div className="routes">
        <ListGroup>{!isLoading && renderList(sections)}</ListGroup>
      </div>


      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}

export default RouteSection;