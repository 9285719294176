import React, { useState, useEffect } from "react";
import "./Home.module.css";
import { Accordion, Stack, Button, ListGroup, Modal, Badge } from 'react-bootstrap';
import RouteMapCard from "../components/RouteMapCard";
import Announcement from "../components/Announcement";
import { useAppContext } from "../libs/contextLib";
import axiosInstance from "../axiosApi";
import axios from 'axios';
import styled from "styled-components";
import Kona from '../images/raxi-kona-black.png';
import Kona_front from '../images/kona2-front-black.png';
import { useTranslation } from 'react-i18next';
import { onError } from "../libs/errorLib";
import { useParams, useHistory } from "react-router-dom";
import config from "../config";

const FlexContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 120px);
	grid-column-gap: 12px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

function PhoneNoCheckModal(props) {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>{t('USERDETAIL_REQ_HEADER')}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('USERDETAIL_REQ_BODY')}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function IntroPage() {
  const { id } = useParams();
  const [accessToken, setAccessToken] = useState('');
  const { isAuthenticated } = useAppContext();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [route, setRoute] = useState([]);
  const { routeMap, setRouteMap } = useAppContext();
  const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
  const { setRouteAppContext } = useAppContext();
  const { setCarAppContext } = useAppContext();
  const { routeAppContext } = useAppContext();
  const { setStrStationAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();
  const { setStrRoundDateAppContext } = useAppContext();
  const { setEndStationAppContext } = useAppContext();
  const { setEndSingleDateAppContext } = useAppContext();
  const { setChargeAppContext } = useAppContext();
  const { setLeadtimeAppContext } = useAppContext();
  const { t } = useTranslation();
  const [modalShow, setModalShow] = React.useState(false);
  const [tempUser, setTempUser] = useState(JSON.parse(localStorage.getItem("access_user")));
  const { currentLocation, setCurrentLocation } = useAppContext();
  setCurrentLocation('/');

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [routeOptions, setRouteOptions] = useState([]);

  function clickHandler() {
    const addBtn = document.querySelector('.add-button');
    addBtn.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
    });
  }

  const installCheck = () => {
    //let deferredPrompt;
    const addBtn = document.querySelector('.add-button');
    if (addBtn !== null) {
      addBtn.style.display = 'none';
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        //deferredPrompt = e;
        setDeferredPrompt(e);
        // Update UI to notify the user they can add to home screen
        addBtn.style.display = 'block';
      });
    }
  }

  //로그인전에 기본값 설정
  const initialPreSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    axios.get(`${config.DJANGO_URL}/robotaxi/routesection_list_allowany/`,{
      params: {route_id: route_id, is_publish: '1'}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      //console.log(response.data);
      setRouteSectionAppContext(response.data[0]);
      section_id = response.data[0].id;

      axios.get(`${config.DJANGO_URL}/robotaxi/routemap_list_allowany/`,{
          params: {id: route_id, section: section_id}
      }).then(function(response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRouteMap(response.data);
        setStrSingleDateAppContext('');
        setStrStationAppContext(response.data[0]);
        s_id = response.data[0].station.id;
        setEndStationAppContext(response.data[response.data.length - 1]);
        e_id = response.data[response.data.length - 1].station.id;
        getChargeAndTime(s_id, e_id, route_id, section_id);

        axios.get(`${config.DJANGO_URL}/robotaxi/robocar_run/`,{
            params: {route_id: route_id, section_id: section_id}
        }).then(function(response) {
          if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
          setCarAppContext(response.data[0]);
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(function (error) {
          console.log(error);
      });
      //delay(3000);
    }).catch(function (error) {
      console.log(error);
    });
  };

  //로그인후에 기본값 설정
  const initialPostSetting = async (route_id) => {
    var s_id = "";
    var e_id = "";
    var section_id = "";

    //가장최근 예약건을 가져와서 디폴트값 셋팅
    axiosInstance.get('/robotaxi/bookingcar/last_booking/',{
      params: {route_id: route_id}
    }).then(function(response) {
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      if (response.data.book_date !== "NONE") {
        section_id = response.data.routesection.id;
        s_id = response.data.s_station.id;
        e_id = response.data.e_station.id;
        setRouteSectionAppContext(response.data.routesection);
        setCarAppContext(response.data.car);
        getChargeAndTime(response.data.s_station.id, response.data.e_station.id, route_id, response.data.routesection.id);
        axiosInstance.get('/robotaxi/routemap_list_allowany/',{
          params: {id: route_id, section: response.data.routesection.id}
        }).then(function(response) {
          setRouteMap(response.data);
          setStrSingleDateAppContext('');
          response.data.map((data) => {
            if (data.station.id == s_id) {
              setStrStationAppContext(data);
            }
            if (data.station.id == e_id) {
              setEndStationAppContext(data);
            }
          })
        }).catch(function (error) {
          console.log(error);
        });               
      }
      else {
        initialPreSetting(route_id);
      }
    }).catch(function (error) {
      console.log(error);
    }); 
  };

  useEffect(() => {
    async function onLoad() {
      console.log("Intropage.js onload() : occured");
      //0. get the all route list
      var route_id = "";

      axios.get(`${config.DJANGO_URL}/robotaxi/route_list_allowany/`, {
        params: { is_publish: '0' }
      }).then(function (response) {
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        setRoute(response.data);
        setRouteOptions(response.data.map(data => ({ name: data.routename, id: data.id, route: data })));

        //1. initial setting : routeappcontext check -> authenticate check
        //2.1 check user info
        if (routeAppContext) {
          if (isAuthenticated) {      
            initialPostSetting(routeAppContext.id);
             //initialPostSetting(route_id);
            axiosInstance.get(`/accounts/user/${tempUser.pk}/`).then(function (response) {
              if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
              if (response.data.phone_number === '') {
                showModal();
              }
            }).catch(function (error) {
              setIsLoading(false);
            });
          } else {
            initialPreSetting(routeAppContext.id);
          }
        } else {
          let idx = 0;
          response.data.map((data_row) => {
            if (tempUser) {
              if (data_row.is_publish && data_row.id == tempUser.route_default) {
                route_id = data_row.id;
                setRouteAppContext(data_row);
                idx++;
              }
            }
          });

          if (idx == 0) {
            route_id = response.data[0].id;
            setRouteAppContext(response.data[0]);
          }

          if (isAuthenticated) {
            initialPostSetting(route_id);
             //initialPostSetting(route_id);
            axiosInstance.get(`/accounts/user/${tempUser.pk}/`).then(function (response) {
              if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
              if (response.data.phone_number === '') {
                showModal();
              }
            }).catch(function (error) {
              setIsLoading(false);
            });
          } else {
            initialPreSetting(route_id);
          }
        }

        // Setting Default Station Value
        setStrSingleDateAppContext('');
        setStrRoundDateAppContext('');
        setEndSingleDateAppContext('');
        setChargeAppContext('');
      }).catch(function (error) {
        setIsLoading(false);
      });
    }
    //console.log(dateFns.format(new Date(), "MM.dd(EE) HH:mm", { locale: ko }));
    onLoad();
    installCheck();
  }, []);

  async function getChargeAndTime(s_id, e_id, route_id, section_id) {
    try {
      const response = await axios.get(`${config.DJANGO_URL}/robotaxi/routemap/resultcircle/`, {
        params: {
          id: route_id,
          section: section_id,
          s_book: s_id,
          e_book: e_id
        }
      });
      if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
      setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      setIsLoading(false);
    }
  }

  function showModal() {
    setModalShow(true);
  }

  function hideModal() {
    setModalShow(false);

    history.push("/userdetail", { replace: true });
  }

  function renderHome() {
    return (
      <Stack gap={3}>
        <div className="card-body">
          <div className="row">
            <div className="col-xs-2">
            </div>
            <div className="col-xs-8">
              <p class="text-center">
                <h5>{t('WELCOME_001')}</h5>
                <Badge bg="success" style={{ marginRight: 10 }}>{routeAppContext.routename}</Badge>
                {(routeAppContext.is_publish) ? (
                  <Badge bg="success" style={{ marginRight: 10 }}>{t('RUNNING')}</Badge>
                ) : (
                  <Badge bg="danger" style={{ marginRight: 10 }}>{t('SUSPENSION')}</Badge>
                )}
                {(routeAppContext.is_round) ? (
                  <Badge bg="success" style={{ marginRight: 10 }}>{t('BOOKOK')}</Badge>
                ) : (
                  <Badge bg="danger" style={{ marginRight: 10 }}>{t('BOOKNOK')}</Badge>
                )}
                <br></br>
                {t('HOME_003')}
              </p>
            </div>
            <div className="col-xs-2">
            </div>
          </div>

          <div className="row">
            <div className="col-3">
            </div>
            <div className="col-6">
              <div class="center-block">
                <img src={Kona_front} class="img-fluid" alt="..."></img>
              </div>
            </div>
            <div className="col-3">
            </div>
          </div>
        </div>
        {/*
      <div>
      <Button variant="outline-success" disabled="true"><b><i class="fa fa-check"></i> {t('TITLE_SERVICE_OP')}</b></Button>
      </div>
      <RouteMapCard route={route} />
      */}
        <Announcement />
        <div>
          <PhoneNoCheckModal
            show={modalShow}
            onHide={hideModal}
          />
        </div>
        <div>
          <br /><br /><br />
        </div>
      </Stack>
    );
  }

  function renderIntro() {
    const onClickLoginHandler = () => {
      history.push("/login");
    };

    const onClickSingupHandler = () => {
      history.push("/signup");
    };

    return (
      <Stack gap={3}>
        {/*
        <button className="add-button" onClick={clickHandler}>Raxi앱 설치하기</button>
        */}
        <div className="card" >
          <div className="card-body">
            <div className="row">
              <div className="col-xs-2">
              </div>
              <div className="col-xs-8">
                <p class="text-center">
                  <h5>{t('HOME_002')}</h5>
                  <Badge bg="success" style={{ marginRight: 10 }}>{routeAppContext.routename}</Badge>
                  <br></br>
                  {t('HOME_003')}
                  {/* apple appstore Notice : Guideline 2.3.10 - Performance - Accurate Metadata
                    <Button 
                      variant="outline-dark" 
                      href="https://play.google.com/store/apps/details?id=ai.sonnet.raxi.twa">
                      <span><i class="fa fa-android fa-fw"></i>
                        &nbsp; {t('TITLE_INSTALL_ANDROID')}
                      </span>
                    </Button>
                    */}
                  <br />
                </p>
              </div>
              <div className="col-xs-2">
              </div>
            </div>

            <div className="row">
              <div className="col-3">
              </div>
              <div className="col-6">
                <div class="center-block">
                  <img src={Kona} class="img-fluid" alt="..."></img>
                </div>
              </div>
              <div className="col-3">
              </div>
            </div>
            <br />
            <br />

            <div className="row">
              <div className="col-3">
              </div>
              <div>
                <FlexContainer>
                  <GridContainer>
                    <Button variant="outline-dark" onClick={onClickSingupHandler}><strong>{t('SIGNUP')}</strong></Button>
                    <Button variant="outline-dark" onClick={onClickLoginHandler}><strong>{t('LOGIN')}</strong></Button>
                  </GridContainer>
                </FlexContainer>
              </div>
              {/* <div className="col-6">
                <Button variant="outline-dark" onClick={onClickSingupHandler}><strong>{t('SIGNUP')}</strong></Button>
                <Button variant="outline-dark" onClick={onClickLoginHandler}><strong>{t('LOGIN')}</strong></Button>
              </div> */}
              <div className="col-3">
              </div>
            </div>
          </div>
        </div>
        <div>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header><span><i class="fa fa-bookmark"></i> {t('COMPANYINFO')}</span></Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>{t('COMPANYINFO1')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO2')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO3')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO4')}</ListGroup.Item>
                  <ListGroup.Item>{t('COMPANYINFO5')}</ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <Announcement />
        <div>
          <br /><br /><br />
        </div>
      </Stack>

    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderHome() : renderIntro()}
    </div>
  );
}