import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col, Stack, Form } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import { CKEditor } from 'ckeditor4-react';
import TableTimePicker from "./TableTimePicker";
import ToggleSwitch from "../components/ToggleSwitch";
import * as dateFns from "date-fns";

function RouteLIneNew() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    routename: "",
    ref_lat: "",
    ref_lng: "",
    defalt_zoom: "12",
    cmsid: "",
    routegubun: "",
    charge: "",
    persons: "",
    sparetime: "60",
    imgsrc: ""
  });
  const [content, setContent] = useState("");
  const [is_publish, setIs_publish] = useState(false);
  const [is_pay, setIs_pay] = useState(false);
  const [is_round, setIs_round] = useState(false);
  const [is_goworkhome, setIs_goworkhome] = useState(false);
  const [goworkfrom, setGoworkfrom] = useState(new Date());
  const [goworkto, setGoworkto] = useState(new Date());
  const [gohomefrom, setGohomefrom] = useState(new Date());
  const [gohometo, setGohometo] = useState(new Date());
  
  const onSwitchActionPublish = () => {
    setIs_publish(!is_publish);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionPay = () => {
    setIs_pay(!is_pay);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionRound = () => {
    setIs_round(!is_round);
    //console.log(isSwitchOnPrivate);
  };

  const onSwitchActionGoWorkHome = () => {
    setIs_goworkhome(!is_goworkhome);
    //console.log(isSwitchOnPrivate);
  };

  function onChangeGoworkfrom(event) {
    console.log(event);
    setGoworkfrom(event);
  }

  function onChangeGoworkto(event) {
    setGoworkto(event);
  }

  function onChangeGohomefrom(event) {
    setGohomefrom(event);
  }

  function onChangeGohometo(event) {
    setGohometo(event);
  }

  function validateForm() {
    return (
      fields.routename.length > 0 &&
      fields.ref_lat.length > 0 &&
      fields.ref_lng.length > 0 &&
      content.length > 0
    );
  }
  
  function createRoute(route) {
    axiosInstance.post(`/robotaxi/route/`, route).then(function(response) {
      return;
    }).catch(function (error) {
      //console.log(error);
      if (error && error.response && error.response.status) {
        if ( error.response.status === 444 ) {
          onError(error.response.data.message);
        } else {
          onError(error);
        }
      } else {
        onError(error);
      }
      setIsLoading(false);
    }); 
  }
  
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      createRoute({
        routename: fields.routename,
        ref_lat: fields.ref_lat,
        ref_lng: fields.ref_lng,
        defalt_zoom: fields.defalt_zoom,
        content: content,
        is_publish: is_publish,
        is_pay: is_pay,
        is_round: is_round,
        cmsid: fields.cmsid,
        routegubun: fields.routegubun,
        charge: fields.charge,
        persons: fields.persons,
        is_goworkhome: is_goworkhome,
        goworkfrom: dateFns.format(goworkfrom,"yyyy-MM-dd'T'HH:mm:00"),
        goworkto: dateFns.format(goworkto,"yyyy-MM-dd'T'HH:mm:00"),
        gohomefrom: dateFns.format(gohomefrom,"yyyy-MM-dd'T'HH:mm:00"),
        gohometo: dateFns.format(gohometo,"yyyy-MM-dd'T'HH:mm:00"),
        sparetime: fields.sparetime,
        imgsrc: fields.imgsrc
      });
    
      history.push("/routeline");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  function onEditorChange( evt ) {
    setContent( evt.editor.getData() );
  }

  return (
  <Stack gap={3}>
      <div className="bg-light border">
        
        <div className="Notes">
          <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="routename">
                <Form.Label>노선명</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.routename}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="ref_lat">
                <Form.Label>위도</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.ref_lat}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="ref_lng">
                <Form.Label>경도</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.ref_lng}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="defalt_zoom">
                <Form.Label>지도Zoom값</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.defalt_zoom}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="cmsid">
                <Form.Label>CMS Route ID</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.cmsid}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="routegubun">
                <Form.Label>노선운영구분(예약호출택시 : A, 고정노선버스 : B )</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.routegubun}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="charge">
                <Form.Label>운임(운영구분A: 건당, 운영구분B: 인당)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.charge}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="persons">
                <Form.Label>탑승인원</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.persons}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="sparetime">
                <Form.Label>여유시간(분)</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.sparetime}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="imgsrc">
                <Form.Label>구간이미지</Form.Label>
                <Form.Control
                  type="text"
                  value={fields.imgsrc}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <Form.Group controlId="goworkhome">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 출퇴근설정</span></Form.Label>
                <Container>
                  <Row xs="auto">
                    <Col>
                      <ToggleSwitch label="출퇴근적용" isChecked={is_goworkhome} onChange={onSwitchActionGoWorkHome}/>
                    </Col>
                  </Row>
                  { is_goworkhome && 
                    <Container>
                      <Row>
                        <Form.Label>출근시간</Form.Label>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={goworkfrom}
                            onChange={onChangeGoworkfrom}
                          />
                        </Col>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={goworkto}
                            onChange={onChangeGoworkto}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label>퇴근시간</Form.Label>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={gohomefrom}
                            onChange={onChangeGohomefrom}
                          />
                        </Col>
                        <Col>
                          <TableTimePicker
                            wrapperClassName="datepicker"
                            selected={gohometo}
                            onChange={onChangeGohometo}
                          />
                        </Col>
                      </Row>
                    </Container>
                  }
                </Container>
              </Form.Group>
              <Form.Group controlId="subject">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
                <Container>
                  <Row xs="auto">
                    <Col>
                      <ToggleSwitch label="운행" isChecked={is_publish} onChange={onSwitchActionPublish}/>
                    </Col>
                    <Col>
                      <ToggleSwitch label="유료" isChecked={is_pay} onChange={onSwitchActionPay}/>
                    </Col>
                    <Col>
                      <ToggleSwitch label="예약" isChecked={is_round} onChange={onSwitchActionRound}/>
                    </Col>
                  </Row>
                </Container>
              </Form.Group>
              <Form.Group controlId="content">
                <Form.Label><span><i class="fas fa-angle-double-right"></i> 내용</span></Form.Label>
                <CKEditor 
                  initData={content} 
                  onChange={onEditorChange}
                />
              </Form.Group>
              <LoaderButton
                block
                size="lg"
                type="submit"
                variant="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Create
              </LoaderButton>
            </Form>
        </div>
        
      </div>
      <div>
        <br /><br /><br />
      </div>
  </Stack>
  );
}

export default RouteLIneNew;