import React, { useState, useCallback } from "react";
import { Container, Row, Col, Stack, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { TwitterPicker } from 'react-color';
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";
import ToggleSwitch from "../components/ToggleSwitch";

function RouteSectionNew() {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const { routeAppContext } = useAppContext();
    const [fields, handleFieldChange] = useFormFields({
        sectionname: "",
        mapurl: "",
        cmsid: "",
        drtid: ""
    });
    const [sectioncolor, setSectioncolor] = useState("");
    const [is_publish, setIs_publish] = useState(false);

    const onSwitchActionPublish = () => {
        setIs_publish(!is_publish);
        //console.log(isSwitchOnPrivate);
      };

    function validateForm() {
        return (
            fields.sectionname.length > 0 &&
            fields.mapurl.length > 0 &&
            sectioncolor.length > 0
        );
    }

    function createProc(section) {
        axiosInstance.post(`/robotaxi/routesection/`, section).then(function (response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            return;
        }).catch(function (error) {
            //console.log(error);
            setIsLoading(false);
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            createProc({
                route: routeAppContext,
                sectionname: fields.sectionname,
                mapurl: fields.mapurl,
                is_publish: is_publish,
                sectioncolor: sectioncolor,
                cmsid: fields.cmsid,
                drtid: fields.drtid
            });

            history.push("/routesection");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    const handleColorChange = useCallback(
        (color) => {
            setSectioncolor(color.hex);
    }, [sectioncolor])

    return (
        <Stack gap={3}>
            <div className="bg-light border">

                <div className="Notes">
                    <h3 className="pb-3 mt-4 mb-3 border-bottom">{t('INPUT_SCREEN')}</h3>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="sectionname">
                            <Form.Label>섹션명</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.sectionname}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="mapurl">
                            <Form.Label>섹션맵 URL</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.mapurl}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="sectioncolor">
                            <Form.Label>색상(HEX)</Form.Label>
                            <Form.Control
                                type="text"
                                value={sectioncolor}
                                disabled={true}
                                onChange={handleColorChange}
                            />
                            <TwitterPicker 
                                color={sectioncolor}
                                onChangeComplete={handleColorChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="subject">
                            <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
                            <Container>
                            <Row xs="auto">
                                <Col>
                                <ToggleSwitch label="공개" isChecked={is_publish} onChange={onSwitchActionPublish}/>
                                </Col>
                            </Row>
                            </Container>
                        </Form.Group>
                        <Form.Group controlId="cmsid">
                            <Form.Label>cmsid</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.cmsid}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="drtid">
                            <Form.Label>drtid</Form.Label>
                            <Form.Control
                                type="text"
                                value={fields.drtid}
                                onChange={handleFieldChange}
                            />
                        </Form.Group>
                        <LoaderButton
                            block
                            size="lg"
                            type="submit"
                            variant="primary"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            Create
                        </LoaderButton>
                    </Form>
                </div>

            </div>
            <div>
                <br /><br /><br />
            </div>
        </Stack>
    );
}

export default RouteSectionNew;