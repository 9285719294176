import React, { useState, useEffect } from "react";
import "./RouteMapDisplay.css";
import { Form, Row, Col, ButtonGroup, Accordion, Card, Button, Badge } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useHistory } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { FaStopCircle } from "react-icons/fa";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";

function StationComponent() {
  const { routeAppContext } = useAppContext();
  const [station, setStation] = useState([]);
  const history = useHistory();

  const { strStationAppContext, setStrStationAppContext } = useAppContext();
  const { endStationAppContext, setEndStationAppContext } = useAppContext();
  const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();

  const [strStationOptions, setStrStationOptions] = useState([]);
  const [endStationOptions, setEndStationOptions] = useState([]);
  const { setLeadtimeAppContext } = useAppContext();
  const { setStrSingleDateAppContext } = useAppContext();

  const [column, setColumn] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    function fetchStation() {
      try {
        axiosInstance.get('/robotaxi/routemap/', {
          params: { id: routeAppContext.id }
        }).then(function (response) {
          //console.log(response.data);
          var s_id = "";
          var e_id = "";
          setStation(response.data);

          if (routeAppContext.routegubun == "B") {
            if (endStationAppContext) {
              response.data.map( (data) => {
                if (data.sequence < endStationAppContext.sequence) {
                  setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
              });
            } else {
              setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
            }
            if (strStationAppContext) {
              response.data.map( (data) => {
                if (data.sequence > strStationAppContext.sequence) {
                  setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
              });
            } else {
              setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
            }
          } else {
            if (endStationAppContext) {
              response.data.map( (data) => {
                if (data.sequence !== endStationAppContext.sequence) {
                  setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
              });
            } else {
              setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
            }
            if (strStationAppContext) {
              response.data.map( (data) => {
                if (data.sequence !== strStationAppContext.sequence) {
                  setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
              });
            } else {
              setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
            }
          }
        
        }).catch(function (error) {
          if (error && error.response && error.response.status) {
            if ( error.response.status === 444 ) {
                onError(error.response.data.message);
            } else {
                onError(error);
            }
        } else {
            onError(error);
        }
        });
      } catch (e) {
        onError(e);
      }
    }
    if (routeAppContext) {
      fetchStation();
    }
  }, []);

  async function getChargeAndTime(s_id, e_id) {
    //console.log(routeAppContext);
    try {
        const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/',{
            params: {
                id: routeAppContext.id,
                section: routeSectionAppContext.id,
                s_book: s_id,
                e_book: e_id
            }
        });
        setLeadtimeAppContext(response.data.sum_leadtime);

    } catch (error) {
      onError(error);
    }
  }

  async function onChangeStrStation(event) {
    var s_id = "";
    const result = strStationOptions.find(option => Number(option.id) === Number(event.target.value));
    if (result && result.station.sequence !== endStationAppContext.sequence) {
      setStrStationAppContext(result.station);
      s_id = result.station.station.id;

      await getChargeAndTime(s_id, endStationAppContext.station.id);
    } else {
      setStrStationAppContext("");
      s_id = "";
    }
    setStrSingleDateAppContext('');
    //console.log(routeAppContext);
    //console.log(station);
    setEndStationOptions([]);
    if (result && routeAppContext.routegubun == "B") {
      // 회차별 운행으로 쉬는 시간 반영, 시점/종점 반영
      station.map( (data) => {
        //console.log(data.sequence);
        if (data.sequence > result.sequence) {
          setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
        }
      });
    } else {
      station.map( (data) => {
        //console.log(data.sequence);
        if (data.sequence !== result.sequence) {
          setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
        }
      });
    }

    //console.log(history.location.pathname);
    if (history.location.pathname == "/bookingstation") {
      history.push("/bookingstationstart", {replace: true});
    } else {
      history.push("/bookingstation", {replace: true});
    }
  }

  async function onChangeEndStation(event) {
    var e_id = "";
    const result = endStationOptions.find(option => Number(option.id) === Number(event.target.value));
    if (result && result.station.sequence !== strStationAppContext.sequence) {
      setEndStationAppContext(result.station);
      e_id = result.station.station.id;

      /**
      var find_rtn = endStationOptions.find(option => option.station.sequence > result.station.sequence);
      if (find_rtn) {
        setStrRoundStationAppContext(find_rtn.station);
      } else {
        setStrRoundStationAppContext(station[0]);
      }
       */
      
      await getChargeAndTime(strStationAppContext.station.id, e_id);
    } else {
      setEndStationAppContext("");
    }

    setStrStationOptions([]);
    if (result && routeAppContext.routegubun == "B") {
      // 회차별 운행으로 쉬는 시간 반영, 시점/종점 반영
      station.map( (data) => {
        if (data.sequence < result.sequence) {
          setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
        }
      });
    } else {
      station.map( (data) => {
        if (data.sequence !== result.sequence) {
          setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
        }
      });
    }

    if (history.location.pathname == "/bookingstation") {
      history.push("/bookingstationend", {replace: true});
    } else {
      history.push("/bookingstation", {replace: true});
    }
  }

  return (
    <div className="bg-white">
      <Form className="form">
        <Row className="mx-0 my-0">
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
              <Col className="my-0" xs={3} md={3}>
                <Form.Label className="formLabel">{t('DEPART')}</Form.Label>
              </Col>
              <Col className="my-0" xs={9} md={9}>
                <DropdownSelector
                  labelName=""
                  options={strStationOptions}
                  defaultValue={strStationAppContext === '' ? '' : strStationAppContext.station.id}
                  onChange={onChangeStrStation}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm={6} >
            <Form.Group as={Row} controlId="formHorizontal">
              <Col className="my-0" xs={3} md={3}>
                <Form.Label className="formLabel">{t('ARRIVAL')}</Form.Label>
              </Col>
              <Col className="my-0" xs={9} md={9}>
                <DropdownSelector
                  labelName=""
                  options={endStationOptions}
                  defaultValue={endStationAppContext === '' ? '' : endStationAppContext.station.id}
                  onChange={onChangeEndStation}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        {/*
            <div className="pt-2 pb-2">
            {station.map((data) => {
              return (
                <>
                  <Button size="sm" className="RouteButton" variant="dark" onClick={(e) => onClickHandler(data,e)}>
                    <FaStopCircle />{' '}{data.station.stationname}{' '}<Badge bg="dark">{data.sequence}</Badge>
                  </Button>{' '}
                </>
              );
            })}
            </div>
          */}
      </Form>
    </div>
  );
}

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: 'pink' }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

function RouteMapDisplay() {
  const { routeAppContext } = useAppContext();
  const { t } = useTranslation();

  return (
    <div className="Destination bg-white">
      <StationComponent />
    </div>
  );
}

{/*
    <Accordion defaultActiveKey="1" flush className="Route">
      <Card className="Card">
        <Card.Header className="header">
          <div className="mx-3">
            {routeAppContext.routename}
          </div>
        </Card.Header>

        <Accordion.Collapse eventKey="1">
          <Card.Body className="body">
            <StationComponent />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  */}

export default RouteMapDisplay;