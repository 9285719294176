import React, { useRef, useState, useEffect } from "react";
import "./Destination.css";
import { Form, Row, Col, ListGroup, Offcanvas, Button, Container, Modal, Collapse } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import TableDateTimePicker from "./TableDateTimePicker";
import { useAppContext } from "../libs/contextLib";
import { FaHandPointRight } from "react-icons/fa";
import axiosInstance from "../axiosApi";
import moment from "moment";
import 'moment/locale/ko';
import { useTranslation } from 'react-i18next';
import DropdownSelector from "../components/DropdownSelector";
import ChooseRouteSection from "./ChooseRouteSection";
import config from "../config";
import * as dateFns from "date-fns";

function BookingResult(props) {
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <h5>출발/도착지 빈좌석 내역</h5>
                {props.bookPersons.map((data) => (
                    <ListGroup key={data.booktime}>
                        <ListGroup.Item>정류장: {data.station.stationname}</ListGroup.Item>
                        <ListGroup.Item>시간: {dateFns.format(new Date(data.booktime), 'HH:mm')}, 빈좌석: {data.persons}</ListGroup.Item>
                    </ListGroup>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function ModalChooseRouteSection(props) {
    const { t } = useTranslation();
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    노선변경화면
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ChooseRouteSection />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function Destination() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [carOptions, setCarOptions] = useState([]);
    const [personsOptions, setPersonsOptions] = useState([]);
    const [routeSectionOptions, setRouteSectionOptions] = useState([]);
    const [persons, setPersons] = useState(0);

    const { setRouteAppContext } = useAppContext();
    const { routeAppContext } = useAppContext();

    const { ticketGubunAppContext } = useAppContext();
    const { setTicketGubunAppContext } = useAppContext();

    const { strRoundDateAppContext } = useAppContext();
    const { setStrRoundDateAppContext } = useAppContext();

    const { endRoundDateAppContext } = useAppContext();
    const { setEndRoundDateAppContext } = useAppContext();

    const { strStationAppContext } = useAppContext();
    const { setStrStationAppContext } = useAppContext();

    const { endStationAppContext } = useAppContext();
    const { setEndStationAppContext } = useAppContext();

    const { endSingleDateAppContext } = useAppContext();
    const { setEndSingleDateAppContext } = useAppContext();

    const { chargeAppContext } = useAppContext();
    const { setChargeAppContext } = useAppContext();

    const { leadtimeAppContext, setLeadtimeAppContext } = useAppContext();
    const { routeSectionAppContext, setRouteSectionAppContext } = useAppContext();
    const { routeMap, setRouteMap } = useAppContext();

    const { setCarAppContext } = useAppContext();
    const { carAppContext } = useAppContext();

    const { setCarRoundAppContext } = useAppContext();
    const { carRoundAppContext } = useAppContext();

    const { setStrSingleDateAppContext } = useAppContext();
    const { strSingleDateAppContext } = useAppContext();

    const [station, setStation] = useState([]);
    const [strStationOptions, setStrStationOptions] = useState([]);
    const [endStationOptions, setEndStationOptions] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShowRouteSection, setModalShowRouteSection] = useState(false);
    const [bookPersons, setBookPersons] = useState([]);

    const { t } = useTranslation();

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );

    function showModal() {
        setModalShow(true);
    }

    function hideModal() {
        setPersonsOptions([]);
        setPersons(0);
        setModalShow(false);
    }

    function showModalRouteSection() {
        setModalShowRouteSection(true);
    }

    function hideModalRouteSection() {
        setModalShowRouteSection(false);
        if (history.location.pathname == "/bookingstation") {
            history.push("/bookingstationstart", { replace: true });
        } else {
            history.push("/bookingstation", { replace: true });
        }
    }

    const onChangeHandlerSingle = (arg_ticket, event) => {
        setTicketGubunAppContext('S');
        setStrRoundDateAppContext('');
        setCarRoundAppContext('');
    }

    var singlelt = 0, roundlt = 0, singlerd = 0, roundrd = 0;

    useEffect(() => {
        async function onLoad() {
            await axiosInstance.get('/robotaxi/robocar_run/', {
                params: { route_id: routeAppContext.id, section_id: routeSectionAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                const cars = response.data;
                setCarOptions(cars.map(data => ({ name: data.carname, id: data.id, car: data })));
            }).catch(function (error) {
                setIsLoading(false);
            });

            await axiosInstance.get('/robotaxi/routesection/', {
                params: { route_id: routeAppContext.id, is_publish: '1' }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setRouteSectionOptions(response.data.map(data => ({ name: data.sectionname, id: data.id, section: data })));
            }).catch(function (error) {
                setIsLoading(false);
            });

            axiosInstance.get('/robotaxi/routemap/', {
                params: { id: routeAppContext.id, section: routeSectionAppContext.id }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                //console.log(response.data);
                var s_id = "";
                var e_id = "";
                setStation(response.data);

                if (routeAppContext.routegubun == "B") {
                    if (endStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence < endStationAppContext.sequence) {
                                setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                    if (strStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence > strStationAppContext.sequence) {
                                setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                } else {
                    if (endStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence !== endStationAppContext.sequence) {
                                setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setStrStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                    if (strStationAppContext) {
                        response.data.map((data) => {
                            if (data.sequence !== strStationAppContext.sequence) {
                                setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                            }
                        });
                    } else {
                        setEndStationOptions(response.data.map(data => ({ name: data.station.stationname, id: data.station.id, station: data })));
                    }
                }
            }).catch(function (error) {
                setIsLoading(false);
            });

        }

        onLoad();
    }, []);

    useEffect(() => {
        if (!strSingleDateAppContext) {
            return;
        }

        //console.log(dateFns.format(dateFns.add(strSingleDateAppContext,{minutes: Number(leadtimeAppContext),}), "yyyy-MM-dd'T'HH:mm:ss"));
        if (routeAppContext.routegubun == "B") {
            axiosInstance.get('/robotaxi/robotimetable/getpersons/', {
                params: {
                    s_date: dateFns.format(strSingleDateAppContext, "yyyy-MM-dd'T'HH:mm:ss"),
                    e_date: dateFns.format(dateFns.add(strSingleDateAppContext, { minutes: Number(leadtimeAppContext), }), "yyyy-MM-dd'T'HH:mm:ss"),
                    car_id: carAppContext.id,
                    route_id: routeAppContext.id,
                    routesection_id: routeSectionAppContext.id
                }
            }).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                //1. check persons == 0 : 탑승 가능 인원이 없는 정류장
                var min_booktime = "";
                var chk_person = 100;
                response.data.persons.map((data) => {
                    if (Number(data.persons) == 0) {
                        setBookPersons(response.data.persons);
                        showModal();
                        return;
                    }

                    if (Number(data.persons) < chk_person) {
                        chk_person = Number(data.persons);
                        min_booktime = data.booktime;
                    }
                });

                //2. booktime == s_time : 탑승인원 표시
                response.data.persons.map((data) => {
                    //console.log(dateFns.format(new Date(data.booktime), 'yyyy-MM-dd HH:mm:ss'));
                    if (dateFns.format(new Date(data.booktime), 'yyyy-MM-dd HH:mm:ss') == dateFns.format(new Date(min_booktime), 'yyyy-MM-dd HH:mm:ss')) {
                        let i = 1;
                        var arr_persons = [];
                        for (; i <= Number(data.persons); i++) {
                            arr_persons.push({ id: i, name: i + " 명" });
                            //setPersonsOptions(personsOptions => [...personsOptions, { id: i, name: i + " 명" }]);
                        }
                        setPersonsOptions(arr_persons);
                        setPersons(1);
                    }
                });
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            let i = 1;
            var arr_persons = [];
            for (; i <= Number(carAppContext.persons); i++) {
                arr_persons.push({ id: i, name: i + " 명" });
            }
            setPersonsOptions(arr_persons);
            setPersons(1);
        }
    }, [carAppContext, leadtimeAppContext, strSingleDateAppContext, strStationAppContext, endStationAppContext]);

    function validateForm() {
        return String(strSingleDateAppContext).length > 0 &&
            (Object.keys(strStationAppContext).length != 0) &&
            (Object.keys(endStationAppContext).length != 0) &&
            persons > 0 &&
            routeAppContext.is_round;
    }

    async function getChargeAndTime() {
        const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
            params: {
                id: routeAppContext.id,
                section: routeSectionAppContext.id,
                s_book: strStationAppContext.station.id,
                e_book: endStationAppContext.station.id
            }
        });
        if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
        let strtime, endtime, rstrtime, rendtime;
        strtime = new Date(strSingleDateAppContext);
        endtime = moment(strtime).add(Number(response.data.sum_leadtime), 'minutes')
        setEndSingleDateAppContext(endtime);

        rstrtime = new Date(strRoundDateAppContext);
        rendtime = moment(rstrtime).add(Number(response.data.sum_leadtime), 'minutes')
        setEndRoundDateAppContext(rendtime);

        if (routeAppContext.is_pay) {
            if (routeAppContext.routegubun == 'B') {
                setChargeAppContext(String(routeAppContext.charge * persons));
            } else {
                setChargeAppContext(String(routeAppContext.charge));
            }
        } else {
            setChargeAppContext(String(0));
        }
        setLeadtimeAppContext(response.data.sum_leadtime);
        singlelt = Number(response.data.sum_leadtime);
        singlerd = Number(response.data.sum_rundistance);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (strRoundDateAppContext) {
            setTicketGubunAppContext('R');
            await getChargeAndTime();
            //await getRoundLeadTime();
        } else {
            setTicketGubunAppContext('S');
            await getChargeAndTime();
        }

        history.push({
            pathname: '/billroute',
            state: {
                singleLeadTime: singlelt,
                roundLeadTime: singlelt,
                singleRunDistance: singlerd,
                roundRunDistance: singlerd,
                persons: persons
            }
        });
        setIsLoading(false);

    }

    function onChangeCar(event) {
        const result = carOptions.find(option => Number(option.id) === Number(event.target.value));
        if (result) {
            setCarAppContext(result.car);
        } else {
            setCarAppContext("");
            setStrSingleDateAppContext("");
        }

        if (history.location.pathname == "/bookingstation") {
            history.push("/bookingstationstart", { replace: true });
        } else {
            history.push("/bookingstation", { replace: true });
        }
    }

    function onChangePersons(event) {
        setPersons(Number(event.target.value));
    }

    function onChangeStrSingleDate(event) {
        // routeAppContext.is_goworkhome 이 적용이면 routeAppContext.sparetime 시간 이후부터 예약가능
        let minutes = 0;
        let ld_goworkfrom = new Date(routeAppContext.goworkfrom);
        let ld_goworkto = new Date(routeAppContext.goworkto);
        let ld_gohomefrom = new Date(routeAppContext.gohomefrom);
        let ld_gohometo = new Date(routeAppContext.gohometo);
        let ld_selectdate = new Date(event);
        minutes = dateFns.differenceInMinutes(new Date(event), new Date())
        if ((routeAppContext.is_goworkhome) &&
            (
                ((
                    ld_selectdate >=
                    new Date(ld_selectdate.getFullYear(), ld_selectdate.getMonth(), ld_selectdate.getDate(), ld_goworkfrom.getHours(), ld_goworkfrom.getMinutes(), 0)
                ) && (
                        ld_selectdate <=
                        new Date(ld_selectdate.getFullYear(), ld_selectdate.getMonth(), ld_selectdate.getDate(), ld_goworkto.getHours(), ld_goworkto.getMinutes(), 0)
                    )
                )
                ||
                (
                    (
                        ld_selectdate >=
                        new Date(ld_selectdate.getFullYear(), ld_selectdate.getMonth(), ld_selectdate.getDate(), ld_gohomefrom.getHours(), ld_gohomefrom.getMinutes(), 0)
                    ) && (
                        ld_selectdate <=
                        new Date(ld_selectdate.getFullYear(), ld_selectdate.getMonth(), ld_selectdate.getDate(), ld_gohometo.getHours(), ld_gohometo.getMinutes(), 0)
                    )
                )
            )
        ) {
            console.log("출퇴근시간 적용");
            // dateFns.format(event,'YYYY-MM-DDTHH:mm:ss')
            let select_date = dateFns.format(event, "HH");
            if (select_date === '00') {
                let minDate = new Date(event);
                minDate.setHours(0);
                setStrSingleDateAppContext(minDate);
            } else {
                setStrSingleDateAppContext(event);
            }
        } else {
            console.log("여유시간 적용");
            if ((minutes > Number(routeAppContext.sparetime)) && dateFns.isAfter(new Date(event), new Date())) {
                // dateFns.format(event,'YYYY-MM-DDTHH:mm:ss')
                let select_date = dateFns.format(event, "HH");
                if (select_date === '00') {
                    let minDate = new Date(event);
                    minDate.setHours(0);
                    setStrSingleDateAppContext(minDate);
                } else {
                    setStrSingleDateAppContext(event);
                }
            } else {
                alert(t("MSG_0002", { time: routeAppContext.sparetime }));
                setStrSingleDateAppContext('');
            }

        }
        setStrRoundDateAppContext('');
    }

    async function onChangeStrStation(event) {
        var s_id = "";
        const result = strStationOptions.find(option => Number(option.id) === Number(event.target.value));
        if (result && result.station.sequence !== endStationAppContext.sequence) {
            setStrStationAppContext(result.station);
            s_id = result.station.station.id;

            try {
                const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
                    params: {
                        id: routeAppContext.id,
                        section: routeSectionAppContext.id,
                        s_book: s_id,
                        e_book: endStationAppContext.station.id
                    }
                });
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setLeadtimeAppContext(response.data.sum_leadtime);
            } catch (error) {
                setIsLoading(false);
            }
        } else {
            setStrStationAppContext("");
            s_id = "";
        }
        setStrSingleDateAppContext('');
        //console.log(routeAppContext);
        //console.log(station);
        setEndStationOptions([]);
        if (result && routeAppContext.routegubun == "B") {
            // 회차별 운행으로 쉬는 시간 반영, 시점/종점 반영
            station.map((data) => {
                //console.log(data.sequence);
                if (data.sequence > result.sequence) {
                    setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        } else {
            station.map((data) => {
                //console.log(data.sequence);
                if (data.sequence !== result.sequence) {
                    setEndStationOptions(endStationOptions => [...endStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        }

        //console.log(history.location.pathname);
        if (history.location.pathname == "/bookingstation") {
            history.push("/bookingstationstart", { replace: true });
        } else {
            history.push("/bookingstation", { replace: true });
        }
    }

    async function onChangeEndStation(event) {
        var e_id = "";
        const result = endStationOptions.find(option => Number(option.id) === Number(event.target.value));
        if (result && result.station.sequence !== strStationAppContext.sequence) {
            setEndStationAppContext(result.station);
            e_id = result.station.station.id;

            /**
            var find_rtn = endStationOptions.find(option => option.station.sequence > result.station.sequence);
            if (find_rtn) {
              setStrRoundStationAppContext(find_rtn.station);
            } else {
              setStrRoundStationAppContext(station[0]);
            }
             */

            try {
                const response = await axiosInstance.get('/robotaxi/routemap/resultcircle/', {
                    params: {
                        id: routeAppContext.id,
                        section: routeSectionAppContext.id,
                        s_book: strStationAppContext.station.id,
                        e_book: e_id
                    }
                });
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                setLeadtimeAppContext(response.data.sum_leadtime);
            } catch (error) {
                setIsLoading(false);
            }
        } else {
            setEndStationAppContext("");
        }

        setStrStationOptions([]);
        if (result && routeAppContext.routegubun == "B") {
            // 회차별 운행으로 쉬는 시간 반영, 시점/종점 반영
            station.map((data) => {
                if (data.sequence < result.sequence) {
                    setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        } else {
            station.map((data) => {
                if (data.sequence !== result.sequence) {
                    setStrStationOptions(strStationOptions => [...strStationOptions, { name: data.station.stationname, id: data.station.id, station: data }]);
                }
            });
        }

        if (history.location.pathname == "/bookingstation") {
            history.push("/bookingstationend", { replace: true });
        } else {
            history.push("/bookingstation", { replace: true });
        }
    }

    const handleClick = () => {
        showModalRouteSection();
    }

    return (
        <>
            <div className="Destination bg-white">
                <Form className="form2" onSubmit={handleSubmit}>
                    <Row className="mx-0 px-0">
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="mx-0 px-0" xs={2} md={3}>
                                    <Form.Label className="formLabel">{t('LINE')}</Form.Label>
                                </Col>
                                <Col className="mx-0 px-2" xs={10} md={9}>
                                    <div className="form">
                                        <label></label>{"   "}
                                        <select value={routeSectionAppContext === '' ? '' : routeSectionAppContext.id} disabled>
                                            {
                                                routeSectionOptions.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>                                  
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6} >       
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="mx-0 px-0" xs={2} md={3}>
                                    <Form.Label className="formLabel">{t('CAR')}</Form.Label>
                                </Col>
                                <Col className="mx-0 px-0" xs={10} md={9}>
                                    <DropdownSelector
                                        labelName=""
                                        options={carOptions}
                                        defaultValue={carAppContext ? carAppContext.id : ""}
                                        onChange={onChangeCar}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mx-0 px-0">
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="mx-0 px-0" xs={2} md={3}>
                                    <Form.Label className="formLabel">{t('DEPART')}</Form.Label>
                                </Col>
                                <Col className="mx-0 px-0" xs={10} md={9}>
                                    <DropdownSelector
                                        labelName=""
                                        options={strStationOptions}
                                        defaultValue={strStationAppContext === '' ? '' : strStationAppContext.station.id}
                                        onChange={onChangeStrStation}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="mx-0 px-0" xs={2} md={3}>
                                    <Form.Label className="formLabel">{t('ARRIVAL')}</Form.Label>
                                </Col>
                                <Col className="mx-0 px-0" xs={10} md={9}>
                                    <DropdownSelector
                                        labelName=""
                                        options={endStationOptions}
                                        defaultValue={endStationAppContext === '' ? '' : endStationAppContext.station.id}
                                        onChange={onChangeEndStation}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mx-0 my-0">
                        <Col sm={6} >
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col className="mx-0 px-0" xs={2} md={3}>
                                    <Form.Label className="formLabel">{t('DEPARTTIME')}</Form.Label>
                                </Col>
                                <Col className="mx-0 my-2 px-2" xs={10} md={9}>
                                    {carAppContext && (
                                        <TableDateTimePicker
                                            wrapperClassName="datepicker"
                                            selected={strSingleDateAppContext}
                                            onChange={onChangeStrSingleDate}
                                            car={carAppContext}
                                        />
                                    )}
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mx-0 my-0">
                        <Col className="mx-0 px-0" xs={8} md={9}>
                            <Form.Group as={Row} controlId="formHorizontal">
                                <Col xs={3} md={2}>
                                    <Form.Label className="formLabel">{t('PERSONS')}</Form.Label>
                                </Col>
                                <Col className="mx-0 px-3"xs={9} md={10}>
                                    <div className="form">
                                        <select name="" onChange={onChangePersons} value={persons}>
                                            {
                                                personsOptions.map(option => (
                                                    <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col className="mx-0 px-0" xs={4} md={3}>
                            <Button size="sm" variant="primary" onClick={handleClick}>{t('LINECHANGE')}
                                    </Button>
                        </Col>
                    </Row>
                    <Row className="mx-1 my-1">
                        <LoaderButton
                            block
                            type="submit"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                        >
                            {t('VERIFYBOOKING')}
                        </LoaderButton>
                    </Row>
                </Form>
            </div>
            <div>
                <BookingResult
                    show={modalShow}
                    onHide={hideModal}
                    bookPersons={bookPersons}
                />
            </div>
            <div>
                <ModalChooseRouteSection
                    show={modalShowRouteSection}
                    onHide={hideModalRouteSection}
                />
            </div>
        </>
    );
}