import React, { useState, useEffect, useCallback } from "react";
import { TwitterPicker } from 'react-color';
import { Container, Row, Col, Stack, Form } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import LoaderButton from "../components/LoaderButton";
import { useTranslation } from 'react-i18next';
import ToggleSwitch from "../components/ToggleSwitch";

function RouteSectionInfo() {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [section, setSection] = useState(null);
    const [sectionname, setSectionname] = useState("");
    const [mapurl, setMapurl] = useState("");
    const [sectioncolor, setSectioncolor] = useState("");
    const [route, setRoute] = useState(null);
    const [is_publish, setIs_publish] = useState(false);
    const [cmsid, setCmsid] = useState("");
    const [drtid, setDrtid] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const onSwitchActionPublish = () => {
        setIs_publish(!is_publish);
        //console.log(isSwitchOnPrivate);
    };

    useEffect(() => {
        async function onLoad() {
            axiosInstance.get(`/robotaxi/routesection/${id}/`).then(function (response) {
                if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
                const rtnData = response.data;
                setSection(rtnData);
                setSectionname(rtnData.sectionname);
                setMapurl(rtnData.mapurl);
                setIs_publish(rtnData.is_publish);
                setSectioncolor(rtnData.sectioncolor);
                setRoute(rtnData.route);
                setCmsid(rtnData.cmsid);
                setDrtid(rtnData.drtid);
            }).catch(function (error) {
                console.log(error);
            });
        }

        onLoad();
    }, [id]);

    function validateForm() {
        return (
            sectionname.length > 0 &&
            mapurl.length > 0 &&
            sectioncolor.length
        );
    }

    function saveProc(section) {
        axiosInstance.put(`/robotaxi/routesection/${id}/`, section).then(function (response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            return;
        }).catch(function (error) {
            //console.log(error);
            setIsLoading(false);
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            saveProc({
                sectionname: sectionname,
                sectioncolor: sectioncolor,
                is_publish: is_publish,
                mapurl: mapurl,
                route: route,
                cmsid: cmsid,
                drtid: drtid
            });

            history.push("/routesection");
        } catch (e) {
            onError(e);
            setIsLoading(false);
        }
    }

    async function deleteProc() {
        return axiosInstance.delete(`/robotaxi/routesection/${id}/`, section).then(function (response) {
            if (typeof response === 'undefined') throw "Raxi App 접속이 종료되었습니다";
            return;
        }).catch(function (error) {
            //console.log(error);
            setIsLoading(false);
        });
    }

    async function handleDelete(event) {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this routesection?"
        );

        if (!confirmed) {
            return;
        }

        setIsDeleting(true);

        try {
            await deleteProc();

            history.push("/routesection");
        } catch (e) {
            onError(e);
            setIsDeleting(false);
        }
    }

    const handleColorChange = useCallback(
        (color) => {
            setSectioncolor(color.hex);
    }, [sectioncolor])	

    return (
        <Stack gap={3}>
            <div className="bg-light border">

                <div className="RouteSection">
                    {section && (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="sectionname">
                                <Form.Label>섹션명</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={sectionname}
                                    onChange={(e) => setSectionname(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="mapurl">
                                <Form.Label>섹션맵 URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={mapurl}
                                    onChange={(e) => setMapurl(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="sectioncolor">
                                <Form.Label>색상(HEX)</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={sectioncolor}
                                    disabled={true}
                                    onChange={(e) => handleColorChange(e.target.value)}
                                />
                                <TwitterPicker 
                                    color={sectioncolor}
                                    onChangeComplete={handleColorChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="subject">
                                <Form.Label><span><i class="fas fa-angle-double-right"></i> 처리지침</span></Form.Label>
                                <Container>
                                    <Row xs="auto">
                                        <Col>
                                            <ToggleSwitch label="공개" isChecked={is_publish} onChange={onSwitchActionPublish}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Form.Group>
                            <Form.Group controlId="cmsid">
                                <Form.Label>cmsid</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={cmsid}
                                    onChange={(e) => setCmsid(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="drtid">
                                <Form.Label>drtid</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={drtid}
                                    onChange={(e) => setDrtid(e.target.value)}
                                />
                            </Form.Group>
                            <LoaderButton
                                block
                                size="lg"
                                type="submit"
                                isLoading={isLoading}
                                disabled={!validateForm()}
                            >
                                Save
                            </LoaderButton>
                            <LoaderButton
                                block
                                size="lg"
                                variant="danger"
                                onClick={handleDelete}
                                isLoading={isDeleting}
                            >
                                Delete
                            </LoaderButton>
                        </Form>
                    )}
                </div>

            </div>
            <div>
                <br /><br /><br />
            </div>
        </Stack>
    );
}

export default RouteSectionInfo;