//Qrcode svg, png download reference : https://gurtn.tistory.com/183
import React, { useState, useEffect } from "react";
import { Nav, Navbar, Stack, Button, Container, Row, Col } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";
import axiosInstance from "../axiosApi";
import "./Settings.module.css";
import { useTranslation } from 'react-i18next';
import { useAppContext } from "../libs/contextLib";
import QRCode from 'qrcode.react';

function Settings() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const { currentLocation, setCurrentLocation } = useAppContext();
  setCurrentLocation('/settings');

  const cities = [
    { name: 'Mumbai, India', position: { lat: 19.07283, lng: 72.88261 } },
    { name: 'Bhubaneshwar, India', position: { lat: 20.296059, lng: 85.824539 } },
    { name: 'Lucknow, India', position: { lat: 26.850000, lng: 80.949997 } },
    { name: 'Delhi, India', position: { lat: 28.610001, lng: 77.230003 } },
    { name: 'Amaravati, India', position: { lat: 14.685564, lng: 77.595406 } },
    { name: 'Itanagar, India', position: { lat: 27.1, lng: 93.62 } },
    { name: 'Guwahati, India', position: { lat: 26.148043, lng: 91.731377 } },
    { name: 'Patna, India', position: { lat: 25.612677, lng: 85.158875 } },
    { name: 'Chandigarh, India', position: { lat: 30.741482, lng: 76.768066 } },
    { name: 'Rome, Italy', position: { lat: 41.890251, lng: 12.492373 } },
    { name: 'London, UK', position: { lat: 51.509865, lng: -.118092 } },
    { name: 'Madrid, Spain', position: { lat: 40.416775, lng: -3.703790 } },
    { name: 'Athens, Greece', position: { lat: 37.983810, lng: 23.727539 } },
    { name: 'Tokyo, Japan', position: { lat: 35.652832, lng: 139.839478 } },
  ]

  useEffect(() => {
    async function onLoad() {
      try {
        axiosInstance.get('/robotaxi/route/').then(function (response) {
          setRoutes(response.data);
        }).catch(function (error) {
          if (error && error.response && error.response.status) {
            if (error.response.status === 444) {
              onError(error.response.data.message);
            } else {
              onError(error);
            }
          } else {
            onError(error);
          }
        });
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, []);

  function changeMapCenter(e) {
    const index = e.target.value
    console.log(cities[index]['position'])
    console.log(cities[index]['name'])
    //this.forceUpdate();
  };

  const downloadQRCode_png = () => {
    const qrCodeURL = document.getElementById('qrCodeEl_png')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode_svg = () => {
    const $svg = document.getElementById('qrCodeEl_svg')
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });

    let aEl = document.createElement("a");
    aEl.href = URL.createObjectURL(blob);;
    aEl.download = "QR_Code.svg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode2_png = () => {
    const qrCodeURL = document.getElementById('qrCodeEl2_png')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code2.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode2_svg = () => {
    const $svg = document.getElementById('qrCodeEl2_svg')
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });

    let aEl = document.createElement("a");
    aEl.href = URL.createObjectURL(blob);;
    aEl.download = "QR_Code2.svg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode3_png = () => {
    const qrCodeURL = document.getElementById('qrCodeEl3_png')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code3.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode3_svg = () => {
    const $svg = document.getElementById('qrCodeEl3_svg')
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });

    let aEl = document.createElement("a");
    aEl.href = URL.createObjectURL(blob);;
    aEl.download = "QR_Code3.svg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode4_png = () => {
    const qrCodeURL = document.getElementById('qrCodeEl4_png')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code4.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCode4_svg = () => {
    const $svg = document.getElementById('qrCodeEl4_svg')
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });

    let aEl = document.createElement("a");
    aEl.href = URL.createObjectURL(blob);;
    aEl.download = "QR_Code4.svg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeAndroid_png = () => {
    const qrCodeURL = document.getElementById('qrCodeElAndroid_png')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_CodeAndroid.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeAndroid_svg = () => {
    const $svg = document.getElementById('qrCodeElAndroid_svg')
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });

    let aEl = document.createElement("a");
    aEl.href = URL.createObjectURL(blob);;
    aEl.download = "QR_CodeAndroid.svg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeIOS_png = () => {
    const qrCodeURL = document.getElementById('qrCodeElIOS_png')
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL)
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_CodeAndroid.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  const downloadQRCodeIOS_svg = () => {
    const $svg = document.getElementById('qrCodeElIOS_svg')
    const data = new XMLSerializer().serializeToString($svg);
    const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });

    let aEl = document.createElement("a");
    aEl.href = URL.createObjectURL(blob);;
    aEl.download = "QR_CodeIOS.svg";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  }

  return (
    <Stack gap={3}>
      <div className="bg-light border titlesubject">
        Raxi App 접속용 QR Code
      </div>
      <div className="bg-light border">
        <Button variant="outline-success" disabled="true" style={{ marginBottom: 15 }}><b><i class="fa fa-check">
        </i>대구 테크노폴리스구간 예약앱 QR Code</b>
        </Button>
        <Container>
          <Row>
            <Col>
              <QRCode
                id="qrCodeEl_png"
                value={`https://raxi.sonnet.ai/intropage/1`}
                size={256}
                renderAs='png'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download PNG"
                onClick={downloadQRCode_png}
              />
            </Col>
            <Col>
              <QRCode
                id="qrCodeEl_svg"
                value={`https://raxi.sonnet.ai/intropage/1`}
                size={256}
                renderAs='svg'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download SVG"
                onClick={downloadQRCode_svg}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        <Button variant="outline-success" disabled="true" style={{ marginBottom: 15 }}><b><i class="fa fa-check">
        </i>WSCE 킨택스 시승구간 예약앱 QR Code</b>
        </Button>
        <Container>
          <Row>
            <Col>
              <QRCode
                id="qrCodeEl2_png"
                value={`https://raxi.sonnet.ai/intropage/2`}
                size={256}
                renderAs='png'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download PNG"
                onClick={downloadQRCode2_png}
              />
            </Col>
            <Col>
              <QRCode
                id="qrCodeEl2_svg"
                value={`https://raxi.sonnet.ai/intropage/2`}
                size={256}
                renderAs='svg'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download SVG"
                onClick={downloadQRCode2_svg}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        <Button variant="outline-success" disabled="true" style={{ marginBottom: 15 }}><b><i class="fa fa-check">
        </i>강릉 관광형 자율주행노선B 예약앱 QR Code</b>
        </Button>
        <Container>
          <Row>
            <Col>
              <QRCode
                id="qrCodeEl3_png"
                value={`https://raxi.sonnet.ai/intropage/3`}
                size={256}
                renderAs='png'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download PNG"
                onClick={downloadQRCode3_png}
              />
            </Col>
            <Col>
              <QRCode
                id="qrCodeEl3_svg"
                value={`https://raxi.sonnet.ai/intropage/3`}
                size={256}
                renderAs='svg'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download SVG"
                onClick={downloadQRCode3_svg}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        <Button variant="outline-success" disabled="true" style={{ marginBottom: 15 }}><b><i class="fa fa-check">
        </i>대구 수성알파시티 구간 예약앱 QR Code</b>
        </Button>
        <Container>
          <Row>
            <Col>
              <QRCode
                id="qrCodeEl4_png"
                value={`https://raxi.sonnet.ai/intropage/4`}
                size={256}
                renderAs='png'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download PNG"
                onClick={downloadQRCode4_png}
              />
            </Col>
            <Col>
              <QRCode
                id="qrCodeEl4_svg"
                value={`https://raxi.sonnet.ai/intropage/4`}
                size={256}
                renderAs='svg'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download SVG"
                onClick={downloadQRCode4_svg}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        <Button variant="outline-success" disabled="true" style={{ marginBottom: 15 }}><b><i class="fa fa-check">
        </i>Raxi 구글플레이스토어 앱다운로드 QR Code</b>
        </Button>
        <Container>
          <Row>
            <Col>
              <QRCode
                id="qrCodeElAndroid_png"
                value={`https://play.google.com/store/apps/details?id=ai.sonnet.raxi.twa`}
                size={256}
                renderAs='png'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download PNG"
                onClick={downloadQRCodeAndroid_png}
              />
            </Col>
            <Col>
              <QRCode
                id="qrCodeElAndroid_svg"
                value={`https://play.google.com/store/apps/details?id=ai.sonnet.raxi.twa`}
                size={256}
                renderAs='svg'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download SVG"
                onClick={downloadQRCodeAndroid_svg}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-light border">
        <Button variant="outline-success" disabled="true" style={{ marginBottom: 15 }}><b><i class="fa fa-check">
        </i>Raxi 애플 IOS 앱다운로드 QR Code</b>
        </Button>
        <Container>
          <Row>
            <Col>
              <QRCode
                id="qrCodeElIOS_png"
                value={`https://apps.apple.com/kr/app/%EC%86%8C%EB%84%A4%ED%8A%B8-%EC%9E%90%EC%9C%A8%EC%A3%BC%ED%96%89-%EB%A1%9C%EB%B3%B4%ED%83%9D%EC%8B%9C-%EC%98%88%EC%95%BD%EC%95%B1-raxi/id6444847943`}
                size={256}
                renderAs='png'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download PNG"
                onClick={downloadQRCodeIOS_png}
              />
            </Col>
            <Col>
              <QRCode
                id="qrCodeElIOS_svg"
                value={`https://apps.apple.com/kr/app/%EC%86%8C%EB%84%A4%ED%8A%B8-%EC%9E%90%EC%9C%A8%EC%A3%BC%ED%96%89-%EB%A1%9C%EB%B3%B4%ED%83%9D%EC%8B%9C-%EC%98%88%EC%95%BD%EC%95%B1-raxi/id6444847943`}
                size={256}
                renderAs='svg'
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={true}
              />
              <br />
              <input
                type="button"
                className="download-btn"
                value="Download SVG"
                onClick={downloadQRCodeIOS_svg}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* 
      <form className="form">
        <select onChange={changeMapCenter}>
          {cities.map((city, index) => {
            return (
              <option value={index}>{city.name}</option>
            )
          })}
        </select>
      </form>
      */}
      <div>
        <br /><br /><br />
      </div>
    </Stack>
  );
}

export default Settings;